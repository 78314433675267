import { ConfigService } from './config-service';
import endpoints from '../types/endpoints.const';
import { PharmacyStatusUpdateRequestDto as PharmacyPrescriptionStatusUpdateRequestDto } from '../types/pharmacy-status-update.dto';
import { NewPrescriptionRequestDto } from '../types/new-prescription.dto';
import PrescriptionWithUpdatesDto from '../types/prescription-with-updates.dto';
import CustomerResponseDto from '../types/customer-response.dto';
import PharmacyResponseDto from '../types/pharmacy-response.dto';
import UpdateCustomerDto from '../types/update-customer.dto';
import PharmacyPrescriptionInfoResponseDto from '../types/pharmacy-prescription-response.dto';

// -----------------------------------------------------------------------------
class ApiService {
  // ---------------------------------------------------------------------------
  static async getUser(email: string, accessToken?: string) {
    return ApiService.get(endpoints.getUser(email), accessToken);
  }

  // ---------------------------------------------------------------------------
  static async getCustomer(
    id: string,
    accessToken?: string,
  ): Promise<CustomerResponseDto> {
    return ApiService.get(endpoints.getCustomer(id), accessToken);
  }

  // ---------------------------------------------------------------------------
  static async updateCustomer(
    id: string,
    info: UpdateCustomerDto,
    accessToken?: string,
  ): Promise<CustomerResponseDto> {
    return ApiService.patch(
      endpoints.updateCustomerInfo(id),
      info,
      accessToken,
    );
  }

  // ---------------------------------------------------------------------------
  static async getPharmacy(
    id: string,
    accessToken?: string,
  ): Promise<PharmacyResponseDto> {
    return ApiService.get(endpoints.getPharmacy(id), accessToken);
  }

  // ---------------------------------------------------------------------------
  static async getPrescription(
    id: string,
    accessToken?: string,
  ): Promise<PrescriptionWithUpdatesDto> {
    return ApiService.get(endpoints.getPrescription(id), accessToken);
  }

  // ---------------------------------------------------------------------------
  static async submitOrder(
    id: string,
    accessToken?: string,
  ): Promise<PrescriptionWithUpdatesDto> {
    return ApiService.get(endpoints.submitOrder(id), accessToken);
  }

  // ---------------------------------------------------------------------------
  static async newPrescription(
    data: NewPrescriptionRequestDto,
    accessToken?: string,
  ): Promise<PrescriptionWithUpdatesDto> {
    return ApiService.post(endpoints.newPrescription, data, accessToken);
  }

  // ---------------------------------------------------------------------------
  static async pharmacyStatusUpdate(
    data: PharmacyPrescriptionStatusUpdateRequestDto,
    accessToken?: string,
  ): Promise<PharmacyPrescriptionInfoResponseDto> {
    return ApiService.post(
      endpoints.statusUpdateFromPharmacy,
      data,
      accessToken,
    );
  }

  // ---------------------------------------------------------------------------
  private static async get(
    endpoint: string,
    acessToken?: string,
  ): Promise<any> {
    const url = `${ConfigService.getApiUrl()}/${endpoint}`;
    const headers = ApiService.getHeaders(acessToken);
    const response = await fetch(url, {
      method: 'GET',
      headers,
    });
    const data = await response.json();
    return data;
  }

  // ---------------------------------------------------------------------------
  private static async post<T>(
    endpoint: string,
    body: T,
    acessToken?: string,
  ): Promise<any> {
    const url = `${ConfigService.getApiUrl()}/${endpoint}`;
    const headers = ApiService.getHeaders(acessToken);
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  }

  // ---------------------------------------------------------------------------
  private static async patch<T>(
    endpoint: string,
    body: T,
    acessToken?: string,
  ): Promise<any> {
    const url = `${ConfigService.getApiUrl()}/${endpoint}`;
    const headers = ApiService.getHeaders(acessToken);
    const response = await fetch(url, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  }

  // ---------------------------------------------------------------------------
  private static getHeaders(accessToken?: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  }
}

export default ApiService;
