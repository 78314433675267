import CopyIcon from '../../assets/svg-components/copy-icon';
import PersonIcon from '../../assets/svg-components/person-icon';
import PhoneIcon from '../../assets/svg-components/phone-icon';
import usePharmacyApi from '../../hooks/usePharmacyApi';
import usePharmacyContext from '../../hooks/usePharmacyContext';
import { PharmacyPrescriptionInfo } from '../../types/pharmacy-prescription-info.type';
import { OrderStatus } from '../../types/order-status.enum';
import SendButton from '../shared/buttons/SendButton';
import PillLoader from '../shared/loaders/PillLoader';
import PulsingNotification from '../shared/notification/PulsingNotification';
import { useState } from 'react';
import PriceIcon from '../../assets/svg-components/price-icon';
import Popup from '../shared/popup/Popup';
import SlidingBackgroundButton from '../shared/buttons/SlidingBackgroundButton';
import './PharmacyPrescriptionCard.scss';
import DateIcon from '../../assets/svg-components/date-icon';

const PharmacyPrescriptionCard = ({
  prescription,
}: {
  prescription: PharmacyPrescriptionInfo;
}) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const [price, setPrice] = useState<number | undefined>(undefined);

  const { updatePrescriptionStatus } = usePharmacyApi();
  const { markNotificationAsSeen } = usePharmacyContext();

  return (
    <div
      className='pharmacy-prescription-card'
      onMouseEnter={() => markNotificationAsSeen(prescription.prescriptionId)}
    >
      {prescription.showNotification && <PulsingNotification message='!' />}

      <Popup
        isVisible={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
      >
        <div className='pharmacy-prescription-confirmation-popup'>
          <div className='pharmacy-prescription-confirmation-popup-header'>
            Confirm this Action!
          </div>

          <div className='pharmacy-prescription-confirmation-popup-body'>
            <div className='pharmacy-prescription-confirmation-popup-body-id'>
              Prescription: {prescription.prescriptionId}
            </div>

            <div className='pharmacy-prescription-confirmation-popup-body-price'>
              Price: {price}€
            </div>

            <div className='pharmacy-prescription-confirmation-popup-body-text'>
              Are you sure you want to accept this prescription?
            </div>
          </div>

          <div className='pharmacy-prescription-confirmation-popup-actions'>
            <SlidingBackgroundButton
              text='Accept'
              color='green'
              onClick={() => {
                updatePrescriptionStatus(
                  prescription.prescriptionId,
                  OrderStatus.AVAILABLE,
                  { price },
                );
                setShowConfirmationPopup(false);
              }}
            />

            <SlidingBackgroundButton
              text='Cancel'
              color='red'
              onClick={() => {
                setShowConfirmationPopup(false);
              }}
            />
          </div>
        </div>
      </Popup>

      <div className='pharmacy-prescription-card-body'>
        <div className='pharmacy-prescription-info-item patient-name'>
          <PersonIcon /> {prescription.patientName}
        </div>

        <div className='pharmacy-prescription-info-item prescription-id'>
          <div
            className='pharmacy-prescription-id-copy-container'
            title='Copy Prescription ID'
            onClick={() => {
              navigator.clipboard.writeText(prescription.prescriptionId);
            }}
          >
            <CopyIcon />
          </div>
          <div> {prescription.prescriptionId}</div>
        </div>

        <div className='pharmacy-prescription-info-item patient-phone'>
          <PhoneIcon />
          {prescription.patientPhone}
        </div>

        {prescription.price && (
          <div className='pharmacy-prescription-info-item prescription-price'>
            <PriceIcon />
            {prescription.price}€
          </div>
        )}

        {!prescription.price && (
          <div className='pharmacy-prescription-price'>
            <input
              className='pharmacy-prescription-price-input'
              type='number'
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
              placeholder='Price'
            />
          </div>
        )}

        <div className='pharmacy-prescription-info-item patient-phone'>
          <DateIcon />
          {prescription.createdAt.toLocaleDateString()}
        </div>
      </div>

      <div className='pharmacy-prescription-card-actions'>
        {prescription.status === OrderStatus.NEW && (
          <div className='accept-reject-buttons'>
            <SlidingBackgroundButton
              text='Accept'
              color='green'
              disabled={!price}
              onClick={() => setShowConfirmationPopup(true)}
            />

            <SlidingBackgroundButton
              text='Reject'
              color='red'
              onClick={() =>
                updatePrescriptionStatus(
                  prescription.prescriptionId,
                  OrderStatus.UNAVAILABLE,
                )
              }
            />
          </div>
        )}

        {prescription.status === OrderStatus.AVAILABLE && (
          <div className='pharmacy-prescription-status-available'>
            <div className='pharmacy-prescription-status-available-text'>
              Waiting for confirmation from customer
            </div>
            <PillLoader />
          </div>
        )}

        {prescription.status === OrderStatus.AWAITING_COMPLETION && (
          <div className='pharmacy-prescription-status-fullfileed'>
            <div className='pharmacy-prescription-status-available-text'>
              Customer has confirmed order
            </div>

            <div
              className='pharmacy-prescription-status-fullfiled-icon'
              onClick={() =>
                updatePrescriptionStatus(
                  prescription.prescriptionId,
                  OrderStatus.COMPLETED,
                )
              }
            >
              <SendButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PharmacyPrescriptionCard;
