import './PillLoader.scss';

const PillLoader = () => {
  return (
    <div className='pill-loader-wrapper'>
      <div className='pill-loader pill'>
        <div className='pill-item'>
          <div className='pill-item-half' />
        </div>
        <div className='pill-shadow' />
      </div>
    </div>
  );
};

export default PillLoader;
