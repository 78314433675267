// -----------------------------------------------------------------------------
export enum OrderStatus {
  NEW = 'new',
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  AWAITING_COMPLETION = 'awaiting_completion',
  COMPLETED = 'completed',
  NOT_SELECTED = 'not_selected',
  CANCELED = 'canceled',
  ERROR = 'error',
}
