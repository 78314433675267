import { FC, PropsWithChildren, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import './Popup.scss';

type PopupProps = PropsWithChildren & {
  isVisible: boolean;
  onClose: () => void;
};

const Popup: FC<PopupProps> = ({ children, isVisible, onClose }) => {
  if (!isVisible) return null;

  // Only close if the background itself is clicked
  const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className='popup-background' onClick={handleBackgroundClick}>
      <div className='popup-wrapper'>
        <div className='popup-content'>{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Popup;
