import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithNavigate } from './auth-provider/Auth0Provider';
import { UserProvider } from './contexts/UserContext';
import { ConfigService } from './services/config-service';
import { EnvEnum } from './types/env.enum';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';

Sentry.init({
  dsn: ConfigService.getSentryDsn(),
  environment: ConfigService.getEnviroment(),
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // Tracing
  // tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const env = ConfigService.getEnviroment();

const WrappedApp = (
  <Auth0ProviderWithNavigate>
    <PostHogProvider
      apiKey={ConfigService.getPostHogApiKey()}
      options={{
        api_host: ConfigService.getPostHogHost(),
        loaded: (posthog) => {
          posthog.register({ env, application: 'webapp' });
        },
      }}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </PostHogProvider>
  </Auth0ProviderWithNavigate>
);

if (env === EnvEnum.Production || env === EnvEnum.Staging) {
  const gaId = ConfigService.getGoogleAnalyticsId();
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(script);

  const scriptConfig = document.createElement('script');
  scriptConfig.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gaId}');
  `;

  document.head.appendChild(scriptConfig);
}

root.render(
  env === EnvEnum.Production ? (
    WrappedApp
  ) : (
    <React.StrictMode>{WrappedApp}</React.StrictMode>
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
