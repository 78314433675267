import { useEffect, useState } from 'react';

const useGeoLocation = () => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [intervalId, setIntervalId] = useState<number | null>(null);

  useEffect(() => {
    getLocation();

    // Update location every 10 seconds
    const id = window.setInterval(() => {
      getLocation();
    }, 10000);

    setIntervalId(id);

    // Cleanup on setInterval
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, []);

  const onLocation = (position: GeolocationPosition) => {
    setLocation(position);
  };

  const onError = (error: GeolocationPositionError) => {
    console.error(error);
  };

  const getLocation = () => {
    try {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(onLocation, onError);
      }
    } catch (error) {
      console.error('error getting location: ', error);
    }
  };

  return {
    coords: location?.coords,
  };
};

export default useGeoLocation;
