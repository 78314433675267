import { useAuth0 } from '@auth0/auth0-react';
import ainigmalogo from '../../assets/images/ainigma-logo.png';
import useUserContext from '../../hooks/useUserContext';
import { ConfigService } from '../../services/config-service';
import HapiIcon from '../../assets/svg-components/hapi-icon';
import GenericButton from '../shared/buttons/GenericButton';
import './Header.scss';

const Header = () => {
  useUserContext();
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div className='header'>
      <div className='header-left'>
        <div className='header-logo'>
          <HapiIcon />
          <div className='header-logo-text'>Hapi</div>
        </div>
      </div>
      <div className='header-right'>
        <div className='actions'>
          {!isAuthenticated ? (
            <GenericButton
              text='Εγγραφή/Σύνδεση'
              onClick={() => loginWithRedirect()}
            />
          ) : (
            <GenericButton
              text='Αποσύνδεση'
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: ConfigService.getAuth0CallbackUrl(),
                  },
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
