import { useEffect, useRef } from 'react';

const useAppFlowContentAnimation = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
          // else {
          //     entry.target.classList.remove('show'); //applied only once or it can be applied everytime
          // }
        });
      },
      {
        threshold: 0.3, // Trigger when 50% of the element is visible
      },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return { elementRef };
};

export default useAppFlowContentAnimation;
