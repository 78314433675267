import { Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { ConfigService } from '../services/config-service';

export const Auth0ProviderWithNavigate: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const domain = ConfigService.getAuth0Domain();
  const clientId = ConfigService.getAuth0ClientId();
  const redirectUri = ConfigService.getAuth0CallbackUrl();
  const audience = ConfigService.getAuth0Audience();

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
