import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { mapPrescriptionWithUpdatesDtoToPrescription } from '../helpers/mapping-helpers';
import ApiService from '../services/api-service';
import UpdateCustomerDto from '../types/update-customer.dto';
import useCustomerContext from './useCustomerContext';

// -----------------------------------------------------------------------------
const useCustomerApi = () => {
  const {
    customerInfo,
    updateCustomerInfo,
    updatePrescription,
    setErrorMessage,
    setLoading,
  } = useCustomerContext();

  const { userInfo } = useContext(UserContext);

  // ---------------------------------------------------------------------------
  const submitSearch = async (userInput: string): Promise<void> => {
    if (isNaN(Number(userInput))) {
      setErrorMessage('Prescription should be a number');
      return;
    }

    if (userInput.length !== 4) {
      setErrorMessage('prescription should be 4 digits');
      return;
    }

    if (!customerInfo.id) {
      setErrorMessage('User not logged in');
      return;
    }

    setErrorMessage('');
    setLoading(true);
    const response = await ApiService.newPrescription(
      {
        prescriptionId: userInput,
        customerId: customerInfo.id,
        location: '1, 1',
      },
      userInfo.accessToken,
    );

    // TODO: handle error
    setLoading(false);
    const prescription = mapPrescriptionWithUpdatesDtoToPrescription(response);

    updatePrescription(prescription);
  };

  // TODO: remove this if we stick with focusedPrescriptionId
  // ---------------------------------------------------------------------------
  // const getPrescription = async (prescriptionId: string): Promise<void> => {
  //   if (!customerInfo.id) {
  //     setErrorMessage('User not logged in');
  //     return;
  //   }

  //   setErrorMessage('');
  //   // setLoading(true);
  //   const response = await ApiService.getPrescription(prescriptionId);

  //   // TODO: handle error
  //   // setLoading(false);
  //   const prescription = mapPrescriptionWithUpdatesDtoToPrescription(response);
  //   updatePrescription(prescription);
  // };

  // ---------------------------------------------------------------------------
  const submitOrder = async (id: string): Promise<void> => {
    setErrorMessage('');
    setLoading(true);
    const response = await ApiService.submitOrder(id, userInfo.accessToken);

    // TODO: handle error
    setLoading(false);

    updatePrescription(mapPrescriptionWithUpdatesDtoToPrescription(response));
  };

  // ---------------------------------------------------------------------------
  const submitCustomerInfoUpdate = async (
    info: UpdateCustomerDto,
  ): Promise<void> => {
    setErrorMessage('');
    setLoading(true);
    const response = await ApiService.updateCustomer(
      customerInfo.id,
      info,
      userInfo.accessToken,
    );
    updateCustomerInfo(response);
    // TODO: handle error
    setLoading(false);
  };

  // ---------------------------------------------------------------------------
  return {
    submitSearch,
    submitOrder,
    submitCustomerInfoUpdate,
  };
};

export default useCustomerApi;
