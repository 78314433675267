import { createContext, FC, PropsWithChildren, useState } from 'react';
import { PharmacyInfo } from '../types/pharmacy-info.type';
import usePharmacyContext from '../hooks/usePharmacyContext';
import usePharmacySocketMessages from '../hooks/usePharmacySocketMessages';

export const defaultPharmacyInfo: PharmacyInfo = {
  id: '',
  name: '',
  email: '',
  address: '',
  phone: '',
  location: {
    latitude: 0,
    longitude: 0,
  },
  isOnline: false,
  prescriptions: [],
};

export const PharmacyContext = createContext<{
  pharmacyInfo: PharmacyInfo;
  loading: boolean;
  errorMessage: string;
  setPharmacyInfo: React.Dispatch<React.SetStateAction<PharmacyInfo>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}>({
  pharmacyInfo: defaultPharmacyInfo,
  loading: false,
  errorMessage: '',
  setPharmacyInfo: () => {},
  setLoading: () => {},
  setErrorMessage: () => {},
});

export const PharmacyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pharmacyInfo, setPharmacyInfo] =
    useState<PharmacyInfo>(defaultPharmacyInfo);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <PharmacyContext.Provider
      value={{
        pharmacyInfo,
        loading,
        errorMessage,
        setPharmacyInfo,
        setLoading,
        setErrorMessage,
      }}
    >
      <PharmacyProviderInner>{children}</PharmacyProviderInner>
    </PharmacyContext.Provider>
  );
};

const PharmacyProviderInner: FC<PropsWithChildren> = ({ children }) => {
  usePharmacyContext();
  usePharmacySocketMessages();
  return <>{children}</>;
};
