import CheckmarkIcon from '../../assets/svg-components/checkmark-icon';
import useCustomerApi from '../../hooks/useCustomerApi';
import { OrderStatus } from '../../types/order-status.enum';
import { PharmacyPrescription } from '../../types/prescription.type';
import SendButton from '../shared/buttons/SendButton';
import PillLoader from '../shared/loaders/PillLoader';
import './PharmacyStatusCard.scss';

const PharmacyStatusCard = (props: { pharmacy: PharmacyPrescription }) => {
  const { pharmacy } = props;
  const { id, status, pharmacyAddress, pharmacyName, pharmacyPhone, distance } =
    pharmacy;
  const { submitOrder } = useCustomerApi();

  return (
    <div className={`pharmacy-status-card ${status}`}>
      <div className='pharmacy-status-card-header'>
        <div className='pharmacy-status-card-header-info'>{pharmacyName}</div>
        <div className='pharmacy-status-card-header-info'>{pharmacyPhone}</div>
        <div className='pharmacy-status-card-header-info'>
          {pharmacyAddress}
        </div>
        {distance !== undefined && distance !== 0 && (
          <div className='pharmacy-status-card-header-info'>
            {`Distance: ${distance.toFixed(2)} Km`}
          </div>
        )}
        <div className='pharmacy-status-card-header-info'>{}</div>
      </div>
      <div className='pharmacy-status-card-body'>
        <div className='current-status'>Status: {status}</div>
        {status === OrderStatus.NEW && <PillLoader />}
        {status === OrderStatus.AVAILABLE && (
          <div
            className='send-order-button-container'
            onClick={() => submitOrder(id)}
          >
            <SendButton />
          </div>
        )}

        {status === OrderStatus.COMPLETED && <CheckmarkIcon />}
      </div>
    </div>
  );
};

export default PharmacyStatusCard;
