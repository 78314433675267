const PhoneIcon = () => {
  return (
    <div className='patient-phone-icon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='svg-icon'
        viewBox='0 0 1024 1024'
        version='1.1'
      >
        <path
          d='M670.19 870.91c-24.45 0-50.53-3.21-78.33-9.6-96.61-22.19-173.3-78.8-229.32-125.64-62.52-52.29-132.62-117.97-178.5-208.74-24.27-48.02-35.55-93.81-35.48-144.03-4.36-84.8 54.81-140.17 99.78-174.49 33.02-25.23 76.49-24.12 108.15 2.77 29.49 25.05 58.71 53.9 86.83 85.74 26.99 30.56 26.72 81.98-0.62 117.06-1.99 2.55-4.09 5-6.21 7.43-1.16 1.35-2.33 2.68-3.45 4.06-24.93 30.47-24.93 30.47-4.11 60.37 47.83 68.7 104.18 111.96 172.27 132.21 5.73 1.69 6.89 0.85 
            9.67-2.07l3.18-3.3c7.58-7.87 14.72-15.3 21.35-23.05 
            18.91-22.13 44.47-35.21 71.99-36.81 26.99-1.47 53.42 8.2 74.24 
            27.51 20.58 19.11 41.6 39.81 62.48 61.53v0.01c39.46 41.07 42.03 91.2 
            7.06 137.53-46.21 61.22-105.84 91.51-180.98 91.51zM300.83 245.37c-6.41 0-12.87 2.32-18.97 6.98-56.57 43.17-80.65 82.74-78.08 128.29l0.05 0.83-0.01 0.83c-0.16 42.04 8.95 78.96 29.54 119.7 41.35 81.82 106.42 142.6 164.62 191.27 51.28 42.89 121.07 94.62 206.24 114.18 91.03 20.91 155.47 1.41 202.84-61.33 18.43-24.42 17.56-44.75-2.81-65.95-20.15-20.97-40.4-40.92-60.22-59.31-9.81-9.11-21.47-13.51-33.44-12.86-12.35 0.71-24.12 6.95-33.18 17.56-7.7 8.99-15.76 17.36-23.55 25.45l-3.14 3.26c-17.68 18.4-40.27 24.21-65.27 16.76-80.57-23.97-146.61-74.21-201.88-153.6-37.93-54.47-31.22-80.62 6.69-126.95 1.46-1.79 2.98-3.54 4.5-5.29 1.48-1.71 2.96-3.4 4.34-5.17 10.76-13.81 12.09-35.98 2.78-46.5-26.38-29.88-53.7-56.86-81.19-80.21-6.22-5.29-13.01-7.94-19.86-7.94z'
        />
      </svg>
    </div>
  );
};

export default PhoneIcon;
