import './AppStateLoader.scss';

const AppStateLoader = () => {
  return (
    <div className='loader-wrapper'>
      <div className='loader'>
        <div className='filling-loader'></div>
      </div>
    </div>
  );
};

export default AppStateLoader;
