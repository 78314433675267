import { useContext, useEffect } from 'react';
import { loggedOutUserInfo, UserContext } from '../contexts/UserContext';
import ApiService from '../services/api-service';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';

// -----------------------------------------------------------------------------
const useUserContext = () => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const posthog = usePostHog();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // ---------------------------------------------------------------------------
  useEffect(() => {
    const { isLoggedIn } = userInfo;
    if (!isAuthenticated) {
      console.log('User is not authenticated');
      setUserInfo(loggedOutUserInfo);
      return;
    }

    if (isLoggedIn) {
      console.log('User is already logged in');
      return;
    }

    if (!user?.email) {
      console.log('no email found!');
      return;
    }

    getUserInfo(user.email);
  }, [isAuthenticated]);

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!userInfo?.email) {
      return;
    }

    Sentry.setUser({
      email: userInfo?.email,
      user_type: userInfo?.userType,
      id: userInfo?.id,
    });

    posthog.identify(userInfo?.id, {
      email: userInfo?.email,
      user_type: userInfo?.userType,
    });
  }, [userInfo?.email]);

  // ---------------------------------------------------------------------------
  // TODO: add dto
  const getUserInfo = async (email: string) => {
    const accessToken = await getAccessTokenSilently();
    const userResponseDto = await ApiService.getUser(email, accessToken);
    setUserInfo({
      id: userResponseDto.userDetails?.id,
      email: email,
      userType: userResponseDto.type,
      accessToken,
      isLoggedIn: true,
    });
  };
};

export default useUserContext;
