import { io } from 'socket.io-client';
import { ConfigService } from './config-service';
import { MESSAGE_TYPES } from '../types/message-types.enum';
import { UserType } from '../types/user-type.type';
import { PharmacyStatusUpdateMessageDto } from '../types/pharmacy-status-update.dto';
import PharmacyPrescriptionInfoResponseDto from '../types/pharmacy-prescription-response.dto';

// -----------------------------------------------------------------------------
export const socket = io(ConfigService.getApiUrl(), { autoConnect: false });

// -----------------------------------------------------------------------------
export interface ISocketDelegate {
  onMessage?: (data: string) => void;
  onNewPrescription?: (data: PharmacyPrescriptionInfoResponseDto) => void;
  onPrescriptionUpdateFromPharmacy?: (
    data: PharmacyStatusUpdateMessageDto,
  ) => void;
}

// -----------------------------------------------------------------------------
export class SocketService {
  private delegate: ISocketDelegate[] = [];
  private static instance: SocketService | null = null;

  // ---------------------------------------------------------------------------
  constructor(userId: string, userType: UserType) {
    socket.io.opts.query = {
      userId: userId,
      type: userType,
    };

    socket.connect();
    this.subscribeToEvents();
  }

  // ---------------------------------------------------------------------------
  public static getInstance(userId: string, userType: UserType) {
    if (!this.instance) {
      this.instance = new SocketService(userId, userType);
    }

    return this.instance;
  }

  // ---------------------------------------------------------------------------
  public addDelegate(delegate: ISocketDelegate) {
    this.delegate.push(delegate);
  }

  // ---------------------------------------------------------------------------
  public removeDelegate(delegate: ISocketDelegate) {
    this.delegate = this.delegate.filter((d) => d !== delegate);
  }

  // ---------------------------------------------------------------------------
  private subscribeToEvents() {
    socket.on(MESSAGE_TYPES.MESSAGE, (data) => {
      this.delegate.forEach((delegate) => {
        if (delegate.onMessage) {
          delegate.onMessage(data.text);
        }
      });
    });

    socket.on(
      MESSAGE_TYPES.NEW_PRESCRIPTION,
      (data: PharmacyPrescriptionInfoResponseDto) => {
        this.delegate.forEach((delegate) => {
          if (delegate.onNewPrescription) {
            delegate.onNewPrescription(data);
          }
        });
      },
    );

    socket.on(
      MESSAGE_TYPES.PHARMACY_UPDATE,
      (data: PharmacyStatusUpdateMessageDto) => {
        this.delegate.forEach((delegate) => {
          if (delegate.onPrescriptionUpdateFromPharmacy) {
            delegate.onPrescriptionUpdateFromPharmacy(data);
          }
        });
      },
    );
  }
}
