import { useContext, useEffect, useState } from 'react';
import { ISocketDelegate, SocketService } from '../services/socket-service';
import { UserType, UserTypeEnum } from '../types/user-type.type';
import { UserContext } from '../contexts/UserContext';
import usePharmacyContext from './usePharmacyContext';
import PharmacyPrescriptionInfoResponseDto from '../types/pharmacy-prescription-response.dto';
import { mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo } from '../helpers/mapping-helpers';

// -----------------------------------------------------------------------------
const usePharmacySocketMessages = () => {
  const { pharmacyInfo, updatePharmacyPrescriptionsInfo } =
    usePharmacyContext();

  // ---------------------------------------------------------------------------
  useEffect(() => {
    class PharmacySocketEvents implements ISocketDelegate {
      private readonly socket: SocketService;

      constructor(userId: string, userType: UserType) {
        this.socket = SocketService.getInstance(userId, userType);
        this.socket.addDelegate(this);
      }

      onNewPrescription(data: PharmacyPrescriptionInfoResponseDto) {
        console.log('New Prescription update: ', JSON.stringify(data));
        if (!pharmacyInfo.id) {
          throw new Error('User not logged in');
        }

        const pharmacyPrescriptionInfo =
          mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo(data);

        updatePharmacyPrescriptionsInfo(pharmacyPrescriptionInfo);
      }

      cleanup() {
        this.socket.removeDelegate(this);
      }
    }

    if (!pharmacyInfo.id) {
      return;
    }

    const pharmacyEvents = new PharmacySocketEvents(
      pharmacyInfo.id,
      UserTypeEnum.Pharmacy,
    );

    return () => {
      pharmacyEvents.cleanup();
    };
  }, [pharmacyInfo.id]);
};

export default usePharmacySocketMessages;
