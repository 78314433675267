import { FC } from 'react';
import './SlidingBackgroundButton.scss';

type SlidingBackgroundButton = {
  text: string;
  color: 'red' | 'green';
  disabled?: boolean;
  onClick: () => void;
};

const SlidingBackgroundButton: FC<SlidingBackgroundButton> = ({
  text,
  color,
  disabled = false,
  onClick,
}) => {
  return (
    <button
      className={`sliding-background-button ${disabled ? 'disabled' : ''} ${color}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SlidingBackgroundButton;
