import { PharmacyPrescriptionInfo } from '../types/pharmacy-prescription-info.type';
import PharmacyPrescriptionInfoResponseDto from '../types/pharmacy-prescription-response.dto';
import PrescriptionResponseDto from '../types/prescription-response.dto';
import PrescriptionStatusResponseDto from '../types/prescription-status-response.dto';
import PrescriptionWithUpdatesDto from '../types/prescription-with-updates.dto';
import { PharmacyPrescription, Prescription } from '../types/prescription.type';

// -----------------------------------------------------------------------------
export const mapPrescriptionWithUpdatesDtoToPrescription = (
  response: PrescriptionWithUpdatesDto,
): Prescription => {
  const prescription = mapPrescriptionResponseDtoToPrescription(
    response.prescription,
  );

  if (response.pharmacyUpdates) {
    const pharmacyPrescriptions = response.pharmacyUpdates.map((update) =>
      mapPrescriptionStatusResponseDtoToPharmacyPrescription(update),
    );

    prescription.pharmacyPrescriptions = pharmacyPrescriptions;
  }

  return prescription;
};

// -----------------------------------------------------------------------------
export const mapPrescriptionResponseDtoToPrescription = (
  response: PrescriptionResponseDto,
): Prescription => {
  const prescription: Prescription = {
    id: response.id,
    prescriptionId: response.prescriptionId,
    patientId: response.patientId,
    fulfilled: response.fulfilled,
    createdAt: new Date(response.createdAt),
    updatedAt: new Date(response.updatedAt),
  };

  return prescription;
};

// -----------------------------------------------------------------------------
export const mapPrescriptionStatusResponseDtoToPharmacyPrescription = (
  response: PrescriptionStatusResponseDto,
): PharmacyPrescription => {
  const pharmacyPrescription: PharmacyPrescription = {
    id: response.id,
    customerId: response.customerId,
    pharmacyId: response.pharmacyId,
    pharmacyAddress: response.pharmacyAddress,
    pharmacyName: response.pharmacyName,
    pharmacyPhone: response.pharmacyPhone,
    pharmacyLocation: response.pharmacyLocation,
    prescriptionId: response.prescriptionId,
    status: response.status,
  };

  return pharmacyPrescription;
};

export const mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo = (
  response: PharmacyPrescriptionInfoResponseDto,
): PharmacyPrescriptionInfo => {
  const pharmacyPrescriptionInfo: PharmacyPrescriptionInfo = {
    id: response.id,
    status: response.status,
    patientId: response.patientId,
    patientName: response.patientName,
    patientPhone: response.patientPhone,
    prescriptionId: response.prescriptionId,
    fullfilled: response.fullfilled,
    showNotification: false,
    createdAt: new Date(response.createdAt),
    updatedAt: new Date(response.updatedAt),
    ...(response.price && { price: response.price }),
    ...(response.notes && { notes: response.notes }),
  };

  return pharmacyPrescriptionInfo;
};
