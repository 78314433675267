import HapiIcon from '../../assets/svg-components/hapi-icon';
import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <div className='left-section'>
        <HapiIcon />
        <div className='header-logo-text'>Hapi</div>
      </div>
      <div className='center-section'>
        <p>&copy; 2024 Hapi by Ainigma Technologies. All rights reserved.</p>
      </div>
      <div className='right-section'>
        <p>Privacy</p>
        <p>Terms</p>
      </div>
    </footer>
  );
};

export default Footer;
