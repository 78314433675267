import CustomerSearch from './CustomerSearch';
import './CustomerRoot.scss';
import PrescriptionInfo from './PrescriptionInfo';
import useCustomerContext from '../../hooks/useCustomerContext';
import CustomerInfoForm from './CustomerInfoForm';
import Loader from '../shared/loaders/Loader';

const CustomerRoot = () => {
  const { customerInfo, loading } = useCustomerContext();
  if (loading) {
    return (
      <div className='customer-root'>
        <Loader />
      </div>
    );
  }

  if (!customerInfo.firstName) {
    // form to update customer info
    return (
      <div className='customer-root'>
        <CustomerInfoForm />
      </div>
    );
  }

  return (
    <div className='customer-root'>
      <h1>Καλώς ήρθες {customerInfo.firstName}</h1>
      <CustomerSearch />

      {customerInfo?.prescriptions?.map((prescription) => (
        <PrescriptionInfo
          key={prescription.prescriptionId}
          prescriptionId={prescription.prescriptionId}
        />
      ))}
    </div>
  );
};

export default CustomerRoot;
