import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { CustomerInfo } from '../types/customer-info.type';
import useCustomerContext from '../hooks/useCustomerContext';
import useCustomerSocketMessages from '../hooks/useCustomerSocketMessages';

export const defaultCustomerInfo: CustomerInfo = {
  id: '',
  firstName: '',
  lastName: '',
  prescriptions: [],
  email: '',
  address: '',
  phone: '',
};

export const CustomerContext = createContext<{
  customerInfo: CustomerInfo;
  focusedPrescriptionId: string;
  loading: boolean;
  errorMessage: string;
  setCustomerInfo: React.Dispatch<React.SetStateAction<CustomerInfo>>;
  setFocusedPrescriptionId: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}>({
  customerInfo: defaultCustomerInfo,
  focusedPrescriptionId: '',
  loading: false,
  errorMessage: '',
  setCustomerInfo: () => {},
  setFocusedPrescriptionId: () => {},
  setLoading: () => {},
  setErrorMessage: () => {},
});

export const CustomerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [customerInfo, setCustomerInfo] =
    useState<CustomerInfo>(defaultCustomerInfo);

  const [focusedPrescriptionId, setFocusedPrescriptionId] =
    useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <CustomerContext.Provider
      value={{
        customerInfo,
        focusedPrescriptionId,
        loading,
        errorMessage,
        setCustomerInfo,
        setFocusedPrescriptionId,
        setLoading,
        setErrorMessage,
      }}
    >
      <CustomerProviderInner>{children}</CustomerProviderInner>
    </CustomerContext.Provider>
  );
};

const CustomerProviderInner: FC<PropsWithChildren> = ({ children }) => {
  useCustomerContext();
  useCustomerSocketMessages();
  return <>{children}</>;
};
