import { useAuth0 } from '@auth0/auth0-react';
import GenericButton from '../shared/buttons/GenericButton';
import Lottie from 'lottie-react';
import lottiePharmacyAnimation from '../../assets/lottie-animations/lottie-pharmacy-animation.json';
import SearchIcon from '../../assets/svg-components/search-icon';
import ClockIcon from '../../assets/svg-components/clock-icon';
import PeopleIcon from '../../assets/svg-components/people-icon';
import manSearchingImage from '../../assets/images/man-searching-location-using-gps.png';
import pharmacyStoreImage from '../../assets/images/pharmacy-store.png';
import './LandingPage.scss';

const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className='landing-page'>
      <section className='hero'>
        <div className='container'>
          <div className='hero-content'>
            <h1>Healthcare Connection Simplified</h1>
            <p>
              Connect with local pharmacies, manage prescriptions, and
              streamline your healthcare experience all in one place.
            </p>
            <div className='cta-buttons'>
              <GenericButton
                size='large'
                type='primary'
                text='Get Started'
                onClick={() => console.log('TODO: link to appstore/playstore')}
              />
              <GenericButton
                size='large'
                type='outline'
                text='Learn More'
                onClick={() => console.log('TODO: link to learn more')}
              />
            </div>
          </div>
          <div className='hero-image'>
            <Lottie
              className='lottie-animation'
              animationData={lottiePharmacyAnimation}
              loop={false}
            />
          </div>
        </div>
      </section>

      <section className='features'>
        <div className='container'>
          <div className='feature-cards'>
            <div className='feature-card'>
              <div className='feature-icon'>
                <SearchIcon />
              </div>
              <h3>Find Pharmacies</h3>
              <p>Easily locate and connect with pharmacies in your area.</p>
            </div>

            <div className='feature-card'>
              <div className='feature-icon'>
                <ClockIcon />
              </div>
              <h3>Quick Service</h3>
              <p>Save time with streamlined prescription management.</p>
            </div>

            <div className='feature-card'>
              <div className='feature-icon'>
                <PeopleIcon />
              </div>
              <h3>Connected Care</h3>
              <p>Stay connected with your healthcare providers.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='services'>
        <div className='container'>
          <div className='service-item'>
            <div className='service-image'>
              <img
                className='app-flow-content-card-screenshot'
                src={manSearchingImage}
                alt=''
              />
              <div className='decoration-circle bottom-left' />
              <div className='decoration-circle top-right' />
            </div>
            <div className='service-content'>
              <h2>For Customers</h2>
              <ul>
                <li>Easy prescription management</li>
                <li>Quick access to medication history</li>
                <li>Direct communication with pharmacies</li>
              </ul>
              <GenericButton
                size='large'
                type='primary'
                text='Learn More'
                onClick={() => console.log('TODO: link to learn more')}
              />
            </div>
          </div>

          <div className='service-item reverse'>
            <div className='service-image'>
              <img
                className='app-flow-content-card-screenshot'
                src={pharmacyStoreImage}
                alt=''
              />
              <div className='decoration-circle bottom-right' />
              <div className='decoration-circle top-left' />
            </div>
            <div className='service-content'>
              <h2>For Pharmacies</h2>
              <ul>
                <li>Efficient customer management</li>
                <li>Digital prescription handling</li>
                <li>Automated inventory updates</li>
              </ul>
              <GenericButton
                size='large'
                type='primary'
                text='Join Network'
                onClick={() =>
                  console.log('TODO: add form for pharmacies to join')
                }
              />
            </div>
          </div>
        </div>
      </section>

      <section className='cta'>
        <div className='container'>
          <h2>Ready to Get Started?</h2>
          <p>
            Join thousands of users who are already benefiting from our
            platform.
          </p>
          <GenericButton
            size='large'
            type='secondary'
            text='Sign Up Now'
            onClick={() => console.log('TODO: link to appstore/playstore')}
          />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
