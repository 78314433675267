import { useContext, useEffect } from 'react';
import {
  CustomerContext,
  defaultCustomerInfo,
} from '../contexts/CustomerContext';
import { UserContext } from '../contexts/UserContext';
import { UserTypeEnum } from '../types/user-type.type';
import ApiService from '../services/api-service';
import { PharmacyPrescription, Prescription } from '../types/prescription.type';
import { PharmacyStatusUpdateMessageDto } from '../types/pharmacy-status-update.dto';
import {
  mapPrescriptionResponseDtoToPrescription,
  mapPrescriptionWithUpdatesDtoToPrescription,
} from '../helpers/mapping-helpers';
import CustomerResponseDto from '../types/customer-response.dto';
import { haversineDistance } from '../helpers/helpers';
import useGeoLocation from './useGeolocation';
import { OrderStatus } from '../types/order-status.enum';

// -----------------------------------------------------------------------------
const useCustomerContext = () => {
  const { coords } = useGeoLocation();
  const { userInfo } = useContext(UserContext);
  const {
    customerInfo,
    focusedPrescriptionId,
    setCustomerInfo,
    setFocusedPrescriptionId,
    setLoading,
    setErrorMessage,
    loading,
  } = useContext(CustomerContext);

  // ---------------------------------------------------------------------------
  // set customer id when user logs in
  useEffect(() => {
    const { id, userType, isLoggedIn } = userInfo;
    if (!isLoggedIn) {
      console.log('User not logged in');
      setErrorMessage('User not logged in');
      clearCustomerInfo();
      return;
    }

    if (id === undefined) {
      console.log('User ID not found');
      setErrorMessage('User ID not found');
      clearCustomerInfo();
      return;
    }

    if (userType !== UserTypeEnum.Customer) {
      console.log('User is not a customer');
      setErrorMessage('User is not a customer');
      clearCustomerInfo();
      return;
    }

    if (customerInfo.id === id) return;
    setCustomerInfo((prev) => ({ ...prev, id }));
    // TODO: add retry logic
    // TODO: some race condition here leads to making multiple requests
    //       might be better to first update the customerInfo and then make the request
    getCustomerInfo(id);
  }, [userInfo.id]);

  // ---------------------------------------------------------------------------
  const getCustomerInfo = async (id: string) => {
    setLoading(true);
    const customerResponseDto = await ApiService.getCustomer(
      id,
      userInfo.accessToken,
    );

    // TODO: handle error

    setLoading(false);

    let prescriptions: Prescription[] = [];
    if (customerResponseDto.prescriptions) {
      prescriptions = customerResponseDto.prescriptions
        .map((p) => mapPrescriptionResponseDtoToPrescription(p))
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    }

    setCustomerInfo({
      id: customerResponseDto.id,
      firstName: customerResponseDto.firstName,
      lastName: customerResponseDto.lastName,
      email: customerResponseDto.email,
      address: customerResponseDto.address,
      phone: customerResponseDto.phone,
      prescriptions: prescriptions,
    });
  };

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (coords) {
      setCustomerInfo({
        ...customerInfo,
        location: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
      });
    }
  }, [coords]);

  // ---------------------------------------------------------------------------
  const changeFocusedPrescription = async (prescriptionId: string) => {
    if (!customerInfo.id) {
      console.log('User not logged in, cannot change focused prescription');
      setErrorMessage('User not logged in, cannot change focused prescription');
      return;
    }

    if (focusedPrescriptionId === prescriptionId) {
      setFocusedPrescriptionId('');
      return;
    }

    // if no pharmacyPrescriptions for selected prescription, fetch them
    if (
      customerInfo.prescriptions.some(
        (p) => p.prescriptionId === prescriptionId && !p.pharmacyPrescriptions,
      )
    ) {
      await fetchPrescription(prescriptionId);
    }

    setFocusedPrescriptionId(prescriptionId);
  };

  // ---------------------------------------------------------------------------
  const updateCustomerInfo = async (
    customerResponseDto: CustomerResponseDto,
  ) => {
    let prescriptions: Prescription[] = [];
    if (customerResponseDto.prescriptions) {
      prescriptions = customerResponseDto.prescriptions.map((p) =>
        mapPrescriptionResponseDtoToPrescription(p),
      );
    }

    setCustomerInfo({
      ...customerInfo,
      id: customerResponseDto.id,
      firstName: customerResponseDto.firstName,
      lastName: customerResponseDto.lastName,
      email: customerResponseDto.email,
      address: customerResponseDto.address,
      phone: customerResponseDto.phone,
      prescriptions: prescriptions,
    });
  };

  // ---------------------------------------------------------------------------
  const updatePrescription = (prescription: Prescription) => {
    prescription.pharmacyPrescriptions =
      prescription.pharmacyPrescriptions?.map((pp) => {
        pp.distance = getDistanceFromCustomer(pp);
        return pp;
      });

    const { prescriptions } = customerInfo;
    let newPrescriptions = [...prescriptions];
    if (prescriptions.some((p) => p.id === prescription.id)) {
      newPrescriptions = newPrescriptions.map((p) =>
        p.id === prescription.id ? prescription : p,
      );
    } else {
      newPrescriptions = [prescription, ...newPrescriptions];
      setFocusedPrescriptionId(prescription.prescriptionId);
    }

    setCustomerInfo((prevState) => ({
      ...prevState,
      prescriptions: newPrescriptions,
    }));
  };

  // ---------------------------------------------------------------------------
  const updatePharmacyPrescriptionStatus = (
    pharmacyPrescription: PharmacyStatusUpdateMessageDto,
  ) => {
    const { prescriptions } = customerInfo;
    if (
      !prescriptions.some(
        (p) => p.prescriptionId === pharmacyPrescription.prescriptionId,
      )
    ) {
      console.log('Prescription not found');
      setErrorMessage('Prescription not found');
      return;
    }

    if (pharmacyPrescription.status === OrderStatus.COMPLETED) {
      fetchPrescription(pharmacyPrescription.prescriptionId);
      return;
    }

    const newPrescriptions = prescriptions.map((p) => {
      if (p.prescriptionId === pharmacyPrescription.prescriptionId) {
        return {
          ...p,
          pharmacyPrescriptions:
            p?.pharmacyPrescriptions === null ||
            p?.pharmacyPrescriptions === undefined
              ? [pharmacyPrescription]
              : p.pharmacyPrescriptions.map((pp: PharmacyPrescription) =>
                  pp.pharmacyId === pharmacyPrescription.pharmacyId
                    ? { ...pp, status: pharmacyPrescription.status }
                    : pp,
                ),
        };
      }
      return p;
    });

    setCustomerInfo((prevState) => ({
      ...prevState,
      prescriptions: newPrescriptions,
    }));
  };

  // ---------------------------------------------------------------------------
  const fetchPrescription = async (prescriptionId: string) => {
    setLoading(true);
    const response = await ApiService.getPrescription(
      prescriptionId,
      userInfo.accessToken,
    );
    setLoading(false);
    const prescription = mapPrescriptionWithUpdatesDtoToPrescription(response);
    updatePrescription(prescription);
  };

  // ---------------------------------------------------------------------------
  const clearCustomerInfo = () => {
    setCustomerInfo(defaultCustomerInfo);
  };

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!customerInfo.location) {
      return;
    }

    setCustomerInfo((prevState) => ({
      ...prevState,
      prescriptions: prevState.prescriptions.map((p) => {
        p.pharmacyPrescriptions = p.pharmacyPrescriptions?.map((pp) => {
          pp.distance = getDistanceFromCustomer(pp);
          return pp;
        });
        return p;
      }),
    }));
  }, [customerInfo.location]);

  // ---------------------------------------------------------------------------
  const getDistanceFromCustomer = (
    pharmacyPrescription: PharmacyPrescription,
  ): number => {
    if (!customerInfo.location) {
      return 0;
    }

    return haversineDistance(
      customerInfo.location.latitude,
      customerInfo.location.longitude,
      pharmacyPrescription.pharmacyLocation.latitude,
      pharmacyPrescription.pharmacyLocation.longitude,
    );
  };

  // ---------------------------------------------------------------------------
  return {
    customerInfo,
    focusedPrescriptionId,
    loading,
    updateCustomerInfo,
    changeFocusedPrescription,
    setErrorMessage,
    setLoading,
    updatePrescription,
    updatePharmacyPrescriptionStatus,
  };
};

export default useCustomerContext;
