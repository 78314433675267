import { useContext } from 'react';
import { UserTypeEnum } from './types/user-type.type';
import Header from './components/layout/Header';
import PharmacyRoot from './components/pharmacy/PharmacyRoot';
import CustomerRoot from './components/customer/CustomerRoot';
import { UserContext } from './contexts/UserContext';
import { CustomerProvider } from './contexts/CustomerContext';
import { PharmacyProvider } from './contexts/PharmacyContext';
import LandingPage from './components/layout/LandingPage';
import Footer from './components/layout/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import AppStateLoader from './components/shared/loaders/AppStateLoader';
import './App.scss';
import { ConfigService } from './services/config-service';
import { EnvEnum } from './types/env.enum';

function App() {
  const { userInfo } = useContext(UserContext);
  const { isLoggedIn, userType } = userInfo;
  const { isAuthenticated, isLoading } = useAuth0();

  const isProduction = ConfigService.getEnviroment() === EnvEnum.Production;

  const showLadingPage = !isLoggedIn && !isAuthenticated;

  return (
    <div className='app'>
      {showLadingPage && (
        <div className='background-wrapper'>
          <div className='background-gradient' />
          <div className='background-overlay' />
        </div>
      )}

      {!isLoading && <Header />}
      <div className='app-body'>
        {isLoading && <AppStateLoader />}
        {showLadingPage && <LandingPage />}
        {isLoggedIn &&
          userType === UserTypeEnum.Customer &&
          (isProduction ? (
            <div>
              The Hapi Customer web app is still under development! Check back
              Soon!
            </div>
          ) : (
            <CustomerProvider>
              <CustomerRoot />
            </CustomerProvider>
          ))}
        {isLoggedIn && userType === UserTypeEnum.Pharmacy && (
          <PharmacyProvider>
            <PharmacyRoot />
          </PharmacyProvider>
        )}
      </div>
      {!isLoading && <Footer />}
    </div>
  );
}

export default App;
