import { useContext } from 'react';
import { mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo } from '../helpers/mapping-helpers';
import ApiService from '../services/api-service';
import { OrderStatus } from '../types/order-status.enum';
import usePharmacyContext from './usePharmacyContext';
import { UserContext } from '../contexts/UserContext';

// -----------------------------------------------------------------------------
const usePharmacyApi = () => {
  const {
    pharmacyInfo,
    updatePharmacyPrescriptionsInfo,
    setErrorMessage,
    setLoading,
  } = usePharmacyContext();

  const { userInfo } = useContext(UserContext);

  // ---------------------------------------------------------------------------
  const updatePrescriptionStatus = async (
    prescriptionId: string,
    status: OrderStatus,
    extra?: {
      price?: number;
      notes?: string;
    },
  ) => {
    setErrorMessage('');
    if (!pharmacyInfo?.id) {
      setErrorMessage('User not logged in');
      return;
    }

    setLoading(true);
    try {
      const res = await ApiService.pharmacyStatusUpdate(
        {
          pharmacyId: pharmacyInfo.id,
          prescriptionId,
          status,
          ...extra,
        },
        userInfo.accessToken,
      );

      updatePharmacyPrescriptionsInfo(
        mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo(res),
      );
    } catch (error: any) {
      setErrorMessage(error?.message);
    }

    setLoading(false);
  };

  // ---------------------------------------------------------------------------
  return { updatePrescriptionStatus };
};

export default usePharmacyApi;
