import { useEffect } from 'react';
import { ISocketDelegate, SocketService } from '../services/socket-service';
import { UserType, UserTypeEnum } from '../types/user-type.type';
import { PharmacyStatusUpdateMessageDto } from '../types/pharmacy-status-update.dto';
import useCustomerContext from './useCustomerContext';

// -----------------------------------------------------------------------------
const useCustomerSocketMessages = () => {
  const { customerInfo, updatePharmacyPrescriptionStatus } =
    useCustomerContext();

  // ---------------------------------------------------------------------------
  useEffect(() => {
    class CustomerSocketEvents implements ISocketDelegate {
      private readonly socket: SocketService;

      // -----------------------------------------------------------------------
      constructor(userId: string, userType: UserType) {
        this.socket = SocketService.getInstance(userId, userType);
        this.socket.addDelegate(this);
      }

      // -----------------------------------------------------------------------
      onPrescriptionUpdateFromPharmacy(data: PharmacyStatusUpdateMessageDto) {
        console.log('Pharmacy update: ', JSON.stringify(data));

        const pharmacyData = data;
        updatePharmacyPrescriptionStatus(pharmacyData);
      }

      // -----------------------------------------------------------------------
      cleanup() {
        this.socket.removeDelegate(this);
      }
    }

    if (!customerInfo?.id) {
      return;
    }

    const pharmacyEvents = new CustomerSocketEvents(
      customerInfo?.id,
      UserTypeEnum.Customer,
    );

    return () => {
      pharmacyEvents.cleanup();
    };
  }, [customerInfo]);
};

export default useCustomerSocketMessages;
