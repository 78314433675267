import { createContext, FC, PropsWithChildren, useState } from 'react';
import { UserInfo } from '../types/user-info.type';

export const loggedOutUserInfo: UserInfo = { isLoggedIn: false };
export const UserContext = createContext<{
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
}>({
  userInfo: loggedOutUserInfo,
  setUserInfo: () => {},
});

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [devUserInfo, setDevUserInfo] = useState<UserInfo>(loggedOutUserInfo);

  return (
    <UserContext.Provider
      value={{
        userInfo: devUserInfo,
        setUserInfo: setDevUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
