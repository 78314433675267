import { FC, useEffect, useRef, useState } from 'react';
import { PharmacyPrescriptionInfo } from '../../types/pharmacy-prescription-info.type';
import PharmacyPrescriptionCard from './PharmacyPrescriptionCard';
import PharmacyListEmptyState from './PharmacyListEmptyState';
import './PharmacyPrescriptionList.scss';

type PharmacyPrescriptionListProps = {
  prescriptions: PharmacyPrescriptionInfo[];
  title: string;
  haveFocus?: boolean;
};

const PharmacyPrescriptionList: FC<PharmacyPrescriptionListProps> = ({
  title,
  prescriptions,
  haveFocus = false,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [focusedPrescriptionId, setFocusedPrescriptionId] =
    useState<string>('');

  const setFocusWrapper = (focusedItem: string) => {
    if (haveFocus) {
      setFocusedPrescriptionId(focusedItem);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (listRef.current && listRef.current.contains(event.target as Node)) {
        const target = event.target as HTMLElement;
        const isListItem = target.closest('.pharmacy-prescription-list-item');
        if (!isListItem) {
          setFocusWrapper('');
        }
      } else {
        setFocusWrapper('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='pharmacy-prescription-list'>
      <div className='pharmacy-prescription-list-header'>
        <div className='pharmacy-prescription-list-header-title'>{title}</div>
      </div>
      <div className='pharmacy-prescription-list-body' ref={listRef}>
        {prescriptions.length > 0 ? (
          prescriptions.map((prescription) => (
            <div
              className={`pharmacy-prescription-list-item ${!!focusedPrescriptionId && focusedPrescriptionId !== prescription.prescriptionId ? 'blur' : ''}`}
              key={`list-item-${prescription.prescriptionId}`}
              onClick={() => setFocusWrapper(prescription.prescriptionId)}
            >
              <PharmacyPrescriptionCard
                key={prescription.prescriptionId}
                prescription={prescription}
              />
            </div>
          ))
        ) : (
          <PharmacyListEmptyState />
        )}
      </div>
    </div>
  );
};

export default PharmacyPrescriptionList;
