import { useContext, useState } from 'react';
import useCustomerApi from '../../hooks/useCustomerApi';
import UpdateCustomerDto from '../../types/update-customer.dto';
import { CustomerContext } from '../../contexts/CustomerContext';
import './CustomerInfoForm.scss';
import Loader from '../shared/loaders/Loader';
import useAppFlowContentAnimation from '../../hooks/useAppFlowContentAnimation';

const CustomerInfoForm = () => {
  const [customerFormInfo, setCustomerFormInfo] = useState<UpdateCustomerDto>({
    firstName: '',
    lastName: '',
    phone: '',
    birthdate: '',
  });

  const { submitCustomerInfoUpdate } = useCustomerApi();
  const { loading } = useContext(CustomerContext);
  const { elementRef } = useAppFlowContentAnimation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomerFormInfo({
      ...customerFormInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitCustomerInfoUpdate(customerFormInfo);
  };

  return (
    <div ref={elementRef} className='customer-info-form'>
      <form onSubmit={handleSubmit}>
        <h3 className='title-customer-info'>Enter your Credentials</h3>
        <div className='input-group'>
          <input
            type='text'
            name='firstName'
            // placeholder="name"
            value={customerFormInfo.firstName}
            onChange={handleChange}
            required
          />
          <label>First Name:</label>
        </div>
        <div className='input-group'>
          <input
            type='text'
            name='lastName'
            // placeholder="name"
            value={customerFormInfo.lastName}
            onChange={handleChange}
            required
          />
          <label>Last Name:</label>
        </div>
        <div className='input-group'>
          <input
            type='text'
            name='phone'
            // placeholder="phone"
            value={customerFormInfo.phone}
            required
            onChange={handleChange}
          />

          <label>Phone:</label>
        </div>

        <div className='input-group'>
          <input
            type='text'
            name='birthdate'
            // placeholder="address"
            value={customerFormInfo.birthdate}
            required
            onChange={handleChange}
          />
          <label>Birth Date (ISO8601)</label>
        </div>
        <button type='submit'>Submit</button>
      </form>
    </div>
  );
};

export default CustomerInfoForm;
