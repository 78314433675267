import SendIcon from '../../../assets/svg-components/send-icon';
import './SendButton.scss';

const SendButton = () => {
  return (
    <button className='send-button'>
      <div className='svg-wrapper-1'>
        <div className='svg-wrapper'>
          <SendIcon />
        </div>
      </div>
      <span className='text'>Send</span>
    </button>
  );
};

export default SendButton;
