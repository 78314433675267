import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { UserTypeEnum } from '../types/user-type.type';
import ApiService from '../services/api-service';
import { mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo } from '../helpers/mapping-helpers';
import {
  defaultPharmacyInfo,
  PharmacyContext,
} from '../contexts/PharmacyContext';
import { PharmacyPrescriptionInfo } from '../types/pharmacy-prescription-info.type';
import { OrderStatus } from '../types/order-status.enum';

// -----------------------------------------------------------------------------
const usePharmacyContext = () => {
  const { userInfo } = useContext(UserContext);
  const { pharmacyInfo, setPharmacyInfo, setLoading, setErrorMessage } =
    useContext(PharmacyContext);

  // ---------------------------------------------------------------------------
  useEffect(() => {
    const { id, userType, isLoggedIn } = userInfo;
    if (!isLoggedIn) {
      console.log('User not logged in');
      setErrorMessage('User not logged in');
      clearPharmacyInfo();
      return;
    }

    if (id === undefined) {
      console.log('User ID not found');
      setErrorMessage('User ID not found');
      clearPharmacyInfo();
      return;
    }

    if (userType !== UserTypeEnum.Pharmacy) {
      console.log('User is not a pharmacy');
      setErrorMessage('User is not a pharmacy');
      clearPharmacyInfo();
      return;
    }

    if (pharmacyInfo.id === id) return;

    // TODO: add retry logic
    // TODO: some race condition here leads to making multiple requests
    //       might be better to first update the pharmacyInfo and then make the request
    getPharmacyInfo(id);
  }, [userInfo.id]);

  // ---------------------------------------------------------------------------
  const getPharmacyInfo = async (id: string) => {
    setLoading(true);
    const pharmacyResponseDto = await ApiService.getPharmacy(
      id,
      userInfo.accessToken,
    );

    // TODO: handle error
    setLoading(false);

    let pharmacyPrescriptionsInfo: PharmacyPrescriptionInfo[] = [];
    if (pharmacyResponseDto.prescriptions) {
      pharmacyPrescriptionsInfo = pharmacyResponseDto.prescriptions.map((pp) =>
        mapPharmacyPrescriptionInfoDtoToPharmacyPrescriptionInfo(pp),
      );
    }

    setPharmacyInfo({
      id: pharmacyResponseDto.id,
      name: pharmacyResponseDto.name,
      email: pharmacyResponseDto.email,
      address: pharmacyResponseDto.address,
      phone: pharmacyResponseDto.phone,
      location: pharmacyResponseDto.location,
      isOnline: pharmacyResponseDto.isOnline,
      prescriptions: sortPharmacyPrescriptions(pharmacyPrescriptionsInfo),
    });
  };

  // ---------------------------------------------------------------------------
  const updatePharmacyPrescriptionsInfo = (
    pharmacyPrescriptionInfo: PharmacyPrescriptionInfo,
  ) => {
    const shouldShowNotification = [
      OrderStatus.NEW,
      OrderStatus.AWAITING_COMPLETION,
    ].includes(pharmacyPrescriptionInfo.status);

    if (shouldShowNotification) {
      pharmacyPrescriptionInfo.showNotification = true;
      playSound();
    }

    setPharmacyInfo((prev) => {
      if (!prev.prescriptions) {
        return { ...prev, prescriptions: [pharmacyPrescriptionInfo] };
      }

      let updatedPrescriptions = prev.prescriptions;
      if (
        !prev.prescriptions.some(
          (pp) => pp.prescriptionId === pharmacyPrescriptionInfo.prescriptionId,
        )
      ) {
        updatedPrescriptions = [
          ...prev.prescriptions,
          pharmacyPrescriptionInfo,
        ];
      } else {
        updatedPrescriptions = prev.prescriptions?.map((pp) => {
          if (pp.prescriptionId === pharmacyPrescriptionInfo.prescriptionId) {
            return pharmacyPrescriptionInfo;
          }
          return pp;
        });
      }

      return {
        ...prev,
        prescriptions: sortPharmacyPrescriptions(updatedPrescriptions),
      };
    });
  };

  // ---------------------------------------------------------------------------
  const markNotificationAsSeen = (prescriptionId: string) => {
    setPharmacyInfo((prev) => {
      if (!prev.prescriptions) return prev;

      const updatedPrescriptions = prev.prescriptions.map((pp) => {
        if (pp.prescriptionId === prescriptionId) {
          pp.showNotification = false;
        }

        return pp;
      });

      return {
        ...prev,
        prescriptions: updatedPrescriptions,
      };
    });
  };

  // ---------------------------------------------------------------------------
  const playSound = () => {
    const audio = new Audio('/notification.mp3');
    audio.muted = true; // Initially play muted
    audio
      .play()
      .then(() => {
        audio.muted = false; // Unmute after playback starts
      })
      .catch((error) => {
        console.log('Audio playback prevented:', error);
        // Optionally show an alert or visual cue here
      });
  };

  // ---------------------------------------------------------------------------
  const sortPharmacyPrescriptions = (
    pharmacyPrescriptions: PharmacyPrescriptionInfo[],
  ) => {
    return pharmacyPrescriptions.sort((a, b) => {
      if (a.status === b.status) {
        return 0;
      }

      if (a.status === OrderStatus.NEW) {
        return -1;
      }

      if (b.status === OrderStatus.NEW) {
        return 1;
      }

      return 0;
    });
  };

  // ---------------------------------------------------------------------------
  const clearPharmacyInfo = () => {
    setPharmacyInfo(defaultPharmacyInfo);
  };

  // ---------------------------------------------------------------------------
  return {
    pharmacyInfo,
    updatePharmacyPrescriptionsInfo,
    markNotificationAsSeen,
    setErrorMessage,
    setLoading,
  };
};

export default usePharmacyContext;
