import { EnvEnum } from '../types/env.enum';

// -----------------------------------------------------------------------------
export class ConfigService {
  // #region general
  // ---------------------------------------------------------------------------
  static getApiUrl(): string {
    return this.getString('REACT_APP_API_URL');
  }

  // ---------------------------------------------------------------------------
  static getEnviroment(): EnvEnum {
    const env = this.getString('REACT_APP_ENV');
    if (env === 'development') {
      return EnvEnum.Development;
    }

    if (env === 'staging') {
      return EnvEnum.Staging;
    }

    if (env === 'production') {
      return EnvEnum.Production;
    }

    throw new Error('Invalid environment');
  }

  // ---------------------------------------------------------------------------
  static getSentryDsn(): string {
    return this.getString('REACT_APP_SENTRY_DSN');
  }

  // ---------------------------------------------------------------------------
  static getGoogleAnalyticsId(): string {
    return this.getString('REACT_APP_GOOGLE_ANALYTICS_ID');
  }

  // #endregion

  // #region Auth0
  // ---------------------------------------------------------------------------
  static getAuth0Domain(): string {
    return this.getString('REACT_APP_AUTH0_DOMAIN');
  }

  // ---------------------------------------------------------------------------
  static getAuth0ClientId(): string {
    return this.getString('REACT_APP_AUTH0_CLIENT_ID');
  }

  // ---------------------------------------------------------------------------
  static getAuth0Audience(): string {
    return this.getString('REACT_APP_AUTH0_AUDIENCE');
  }

  // ---------------------------------------------------------------------------
  static getAuth0CallbackUrl(): string {
    return this.getString('REACT_APP_AUTH0_CALLBACK_URL');
  }

  // #endregion

  // #region PostHog
  // ---------------------------------------------------------------------------
  static getPostHogApiKey(): string {
    return this.getString('REACT_APP_PUBLIC_POSTHOG_KEY');
  }

  // ---------------------------------------------------------------------------
  static getPostHogHost(): string {
    return this.getString('REACT_APP_PUBLIC_POSTHOG_HOST');
  }

  //#endregion

  // #region private
  // ---------------------------------------------------------------------------
  private static getString(key: string): string {
    return this.getKey(key);
  }

  // ---------------------------------------------------------------------------
  private static getNumber(key: string): number {
    return Number(this.getKey(key));
  }

  // ---------------------------------------------------------------------------
  private static getBoolean(key: string): boolean {
    return this.getKey(key) === 'true';
  }

  // ---------------------------------------------------------------------------
  private static getKey(key: string): string {
    const value = process.env[key];
    if (!value) {
      throw new Error(`Missing environment variable: ${key}`);
    }

    return value;
  }

  // #endregion
}
