const getUser = (email: string): string => `user?email=${email}`;

// -----------------------------------------------------------------------------
// Customer endpoints
// -----------------------------------------------------------------------------
const getCustomer = (id: string): string => `customers/${id}`;
const updateCustomerInfo = (id: string): string => `customers/${id}`;

// -----------------------------------------------------------------------------
// Pharmacy endpoints
// -----------------------------------------------------------------------------
const getPharmacy = (id: string): string => `pharmacies/${id}`;
const openPharmacy = (id: string): string => `pharmacies/${id}/open`;
const closePharmacy = (id: string): string => `pharmacies/${id}/close`;

// -----------------------------------------------------------------------------
// Prescription endpoints
// -----------------------------------------------------------------------------
const statusUpdateFromPharmacy = 'prescriptions/update-status';
const submitOrder = (id: string): string => `prescriptions/${id}/submit-order`;
const newPrescription = 'prescriptions/new';
const getPrescription = (id: string): string => `prescriptions/${id}`;

// -----------------------------------------------------------------------------
export default {
  getUser,
  getCustomer,
  updateCustomerInfo,
  getPharmacy,
  openPharmacy,
  closePharmacy,
  statusUpdateFromPharmacy,
  submitOrder,
  newPrescription,
  getPrescription,
};
