import { FC } from 'react';
import './GenericButton.scss';

type GenericButtonProps = {
  text: string;
  type?: 'primary' | 'secondary' | 'outline' | 'ghost';
  size?: 'small' | 'large';
  disabled?: boolean;
  onClick?: () => void;
};

const GenericButton: FC<GenericButtonProps> = ({
  text,
  type = 'primary',
  size = 'small',
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <div className={`generic-button ${type}`} onClick={() => onClick()}>
      {text}
    </div>
  );
};

export default GenericButton;
