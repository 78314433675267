import { FC } from 'react';
import './PulsingNotification.scss';

type PulsingNotificationProps = {
  message: string;
};

const PulsingNotification: FC<PulsingNotificationProps> = ({
  message,
}: PulsingNotificationProps) => {
  return (
    <div className='pulsing-notification'>
      <div className='pulsing-notification-text'>{message}</div>
    </div>
  );
};

export default PulsingNotification;
