import { useContext, useState } from 'react';
import SearchIcon from '../../assets/svg-components/search-icon';
import useCustomerApi from '../../hooks/useCustomerApi';
import Loader from '../shared/loaders/Loader';
import './CustomerSearch.scss';
import { CustomerContext } from '../../contexts/CustomerContext';

const CustomerSearch = () => {
  const [userInput, setUserInput] = useState<string>('');
  const { loading, errorMessage } = useContext(CustomerContext);

  const { submitSearch } = useCustomerApi();

  const onSubmit = () => {
    submitSearch(userInput);
  };

  if (loading) {
    return (
      <div className='customer-search-wrapper'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='customer-search-wrapper'>
      <div className='search-input-wrapper'>
        <div className='ghost-component' />

        <input
          className='search-input'
          placeholder='Enter prescription number'
          type='text'
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
        />

        <div className='search-button' onClick={onSubmit}>
          <SearchIcon />
        </div>
      </div>
      <div className='search-action-wrapper'>
        <div className='search-action-button' onClick={onSubmit}>
          Search
        </div>
      </div>

      <div className='search-status'>
        {errorMessage !== '' && (
          <div className='error-message'>{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default CustomerSearch;
