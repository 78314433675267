import EmptyStateIcon from '../../assets/svg-components/empty-state-icon';
import './PharmacyListEmptyState.scss';

const PharmacyListEmptyState = () => {
  return (
    <div className='pharmacy-prescription-list-empty-state'>
      <div className='pharmacy-prescription-list-empty-state-title'>
        No prescriptions to display
      </div>

      <div className='pharmacy-prescription-list-empty-state-subtitle'>
        Check back later for updates
      </div>

      <EmptyStateIcon />
    </div>
  );
};

export default PharmacyListEmptyState;
