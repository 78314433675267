const PersonIcon = () => {
  return (
    <div className='patient-icon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='svg-icon'
        viewBox='0 0 1024 1024'
        version='1.1'
      >
        <path
          d='M512 597.994667q108.010667 0 225.002667 46.997333t116.992 123.008l0 85.994667-684.010667 0 0-85.994667q0-76.010667 116.992-123.008t225.002667-46.997333zM512 
            512q-69.994667 0-120-50.005333t-50.005333-120 50.005333-121.002667 120-51.008 120 51.008 50.005333 121.002667-50.005333 120-120 50.005333z'
        />
      </svg>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M512 597.994667q108.010667 0 225.002667 46.997333t116.992 123.008l0 85.994667-684.010667 0 0-85.994667q0-76.010667 116.992-123.008t225.002667-46.997333zM512 512q-69.994667 0-120-50.005333t-50.005333-120 50.005333-121.002667 120-51.008 120 51.008 50.005333 121.002667-50.005333 120-120 50.005333z'
      />
    </div>
  );
};

export default PersonIcon;
