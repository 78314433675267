import { OrderStatus } from './order-status.enum';

export const activePharmacyStatuses = [
  OrderStatus.AVAILABLE,
  OrderStatus.NEW,
  OrderStatus.AWAITING_COMPLETION,
  OrderStatus.COMPLETED,
];

export type PharmacyPrescriptionInfo = {
  id: string;
  status: OrderStatus;
  patientId: string;
  patientName: string;
  patientPhone: string;
  prescriptionId: string;
  fullfilled: boolean;
  price?: number;
  notes?: string;
  showNotification: boolean;
  createdAt: Date;
  updatedAt: Date;
};
