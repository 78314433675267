import { FC } from 'react';
import PharmacyStatusCard from './PharmacyStatusCard';
import { PharmacyPrescription } from '../../types/prescription.type';
import { OrderStatus } from '../../types/order-status.enum';
import { activePharmacyStatuses } from '../../types/pharmacy-prescription-info.type';
import useCustomerContext from '../../hooks/useCustomerContext';
import './PrescriptionInfo.scss';
import CheckmarkIcon from '../../assets/svg-components/checkmark-icon';

type PrescriptionInfoProps = {
  prescriptionId: string;
};

const PrescriptionInfo: FC<PrescriptionInfoProps> = ({ prescriptionId }) => {
  const { customerInfo, focusedPrescriptionId, changeFocusedPrescription } =
    useCustomerContext();

  const prescription = customerInfo?.prescriptions.find(
    (p) => p.prescriptionId === prescriptionId,
  );

  const pharmacyUpdates = prescription?.pharmacyPrescriptions || [];
  const anyPharmacyUpdates = pharmacyUpdates.length > 0;

  // TODO: should happen in useCustomerContext
  const activePharmacies = anyPharmacyUpdates
    ? pharmacyUpdates
        .filter((p) => activePharmacyStatuses.includes(p.status))
        .sort((a, b) => {
          let distance = 0;
          if (a.distance && b.distance) {
            distance = a.distance - b.distance;
          }

          if (a.status === b.status) {
            return distance;
          } else if (a.status === OrderStatus.NEW) {
            return 1;
          }
          return -1;
        })
    : [];

  const isFulfilling = activePharmacies.some(
    (p) => p.status === OrderStatus.AWAITING_COMPLETION,
  );

  return (
    <div className='prescription-info-wrapper'>
      <div
        className='prescription-info-header'
        onClick={() => changeFocusedPrescription(prescriptionId)}
      >
        <div className='prescription-info-header-text'>
          Prescription ID: {prescriptionId}{' '}
          {prescription?.fulfilled && <CheckmarkIcon />}
        </div>
        <div
          className={`collapse-toggle-button ${focusedPrescriptionId === prescriptionId ? 'flip' : ''}`}
        >
          ^
        </div>
      </div>
      {anyPharmacyUpdates && (
        <div
          className={`prescription-info-body ${focusedPrescriptionId !== prescriptionId ? 'hide' : ''}`}
        >
          <div className='prescription-order-header'>
            <div className='prescription-order-header-subtitle'>
              {prescription?.fulfilled
                ? 'Order has been completed'
                : isFulfilling
                  ? 'Order is ready for pickup'
                  : `${
                      activePharmacies.length > 1
                        ? `${activePharmacies.length} Pharmacies are`
                        : `${activePharmacies.length} Pharmacy is`
                    } trying to fulfill your order`}
            </div>
          </div>

          <div className='pharmacy-status-cards-wrapper'>
            {activePharmacies.map((data: PharmacyPrescription) => (
              <PharmacyStatusCard key={data.pharmacyId} pharmacy={data} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrescriptionInfo;
