import { useContext, useEffect, useState } from 'react';
import PharmacyPrescriptionCard from './PharmacyPrescriptionCard';
import { PharmacyContext } from '../../contexts/PharmacyContext';
import { PharmacyPrescriptionInfo } from '../../types/pharmacy-prescription-info.type';
import { OrderStatus } from '../../types/order-status.enum';
import PharmacyListEmptyState from './PharmacyListEmptyState';
import './PharmacyRoot.scss';
import PharmacyPrescriptionList from './PharmacyPrescriptionList';
import PulsingNotification from '../shared/notification/PulsingNotification';

enum PharmacyOrdersTabs {
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

const PharmacyRoot = () => {
  const { pharmacyInfo, loading, errorMessage } = useContext(PharmacyContext);
  const prescriptions = pharmacyInfo.prescriptions ?? [];

  const [activeTab, setActiveTab] = useState<PharmacyOrdersTabs>(
    PharmacyOrdersTabs.ACTIVE,
  );

  const newPrescriptions = prescriptions.filter((prescription) => {
    return [OrderStatus.NEW, OrderStatus.AVAILABLE].includes(
      prescription.status,
    );
  });

  const prescriptionsAwaitingPickup = prescriptions.filter(
    (prescription) => prescription.status === OrderStatus.AWAITING_COMPLETION,
  );

  const completedPrescriptions = prescriptions.filter(
    (prescription) => prescription.status === OrderStatus.COMPLETED,
  );

  return (
    <div className='pharmacy-main'>
      <div className='pharmacy-main-header'>
        <div className='pharmacy-main-header-tabs'>
          <div
            className={`pharmacy-main-header-tab ${
              activeTab === PharmacyOrdersTabs.ACTIVE ? 'selected' : ''
            }`}
            onClick={() => setActiveTab(PharmacyOrdersTabs.ACTIVE)}
          >
            <div className='pharmacy-main-header-tab-title'>Active</div>
            {[...newPrescriptions, ...prescriptionsAwaitingPickup].some(
              (pp) => pp.showNotification,
            ) && (
              <PulsingNotification
                message={[...newPrescriptions, ...prescriptionsAwaitingPickup]
                  .filter((pp) => pp.showNotification)
                  .length.toString()}
              />
            )}
          </div>
          <div
            className={`pharmacy-main-header-tab ${
              activeTab === PharmacyOrdersTabs.COMPLETED ? 'selected' : ''
            }`}
            onClick={() => setActiveTab(PharmacyOrdersTabs.COMPLETED)}
          >
            <div className='pharmacy-main-header-tab-title'>Completed</div>
            {completedPrescriptions.some((pp) => pp.showNotification) && (
              <PulsingNotification
                message={completedPrescriptions
                  .filter((pp) => pp.showNotification)
                  .length.toString()}
              />
            )}
          </div>
        </div>
      </div>

      <div className='pharmacy-main-body'>
        {activeTab === PharmacyOrdersTabs.ACTIVE && (
          <>
            <PharmacyPrescriptionList
              prescriptions={prescriptions.filter((prescription) => {
                return [OrderStatus.NEW, OrderStatus.AVAILABLE].includes(
                  prescription.status,
                );
              })}
              title='New Prescriptions'
              haveFocus
            />
            <PharmacyPrescriptionList
              prescriptions={prescriptions.filter(
                (prescription) =>
                  prescription.status === OrderStatus.AWAITING_COMPLETION,
              )}
              title='Awaiting Pickup'
            />
          </>
        )}

        {activeTab === PharmacyOrdersTabs.COMPLETED && (
          <PharmacyPrescriptionList
            prescriptions={prescriptions.filter(
              (prescription) => prescription.status === OrderStatus.COMPLETED,
            )}
            title='Completed Orders'
          />
        )}
      </div>
    </div>
  );
};

export default PharmacyRoot;
